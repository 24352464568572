<template>
  <div>
    <div v-if="!isLoading">
      <LoadingProductList></LoadingProductList>
    </div>
    <div v-else>
      <!-- PC -->
      <div class="d-none d-lg-block">
        <div v-for="list in productlist_banner" :key="list">
          <a :href="list.targetUrl">
            <img :src="list.image" alt="全系列商品" class="w-100">
          </a>
        </div>
      </div>
      <!-- 手機 -->
      <div class="d-lg-none">
        <div v-for="list in productlist_m_banner" :key="list">
          <a :href="list.targetUrl">
            <img :src="list.image" alt="全系列商品" class="w-100">
          </a>
        </div>
      </div>
      <!-- 各個產品 -->
      <!-- API寫法 -->
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-lg-10">
            <div class="row pb-5 justify-content-center pt-4 pt-md-5">
              <div class="col-md-12 mb-md-3">
                <div class="row gx-4 gx-md-3 gy-5 mb-md-5">
                  <div
                    class="col-md-3 col-6 position-relative"
                    v-for="(item, index) in productItem"
                    :key="index"
                  >
                    <div class="card p-0 card-hover position-relative">
                      <router-link
                        :to="`/product/${item.Id}`"
                        class="text-secondary"
                      >
                        <div class="imgContainer ratio ratio-1x1">
                          <img
                            :src="item.Image"
                            class="card-img-top border-0"
                            alt="product image"
                          />
                        </div>
                        <!-- 已售完 -->
                        <div
                          class="position-absolute start-0 top-0 opacity-75 sold_out"
                          v-if="item.RealStock === 0"
                        >
                          <div
                            class="contain position-absolute top-50 start-50 translate-middle d-flex justify-content-center align-items-center"
                          >
                            已售完
                          </div>
                        </div>
                        <div
                          class="position-absolute start-0 top-0 opacity-75 sold_out"
                          v-else-if="item.DisplayStock === 0"
                        >
                          <div
                            class="contain position-absolute top-50 start-50 translate-middle d-flex justify-content-center align-items-center"
                          >
                            售完補貨中
                          </div>
                        </div>
                        <div class="card-body px-3 pb-0">
                          <div class="d-flex">
                            <p class="d-inline-block text-white bg-primary px-2" style="font-size: 14px; text-align: center; width: 117px; overflow: hidden; height: 21px;">{{ item.Slogan }}</p>
                          </div>
                          <p
                            class="card-title card-title-height word-break fs-lg-10 fs-md-7 fs-10 p-1 text-break"
                          >
                            {{ item.Name }}
                          </p>
                          <div
                            style="display: flex; align-items: center; justify-content: start"
                          >
                            <small
                              class="card-text fs-lg-6 fs-md-7 fs-7"
                              style="text-decoration: line-through; width: 3.5rem"
                              >${{ item.MSRP.toLocaleString() }}</small
                            >
                            <p
                              class="text-nowrap text-danger mb-0 fw-bolder fs-lg-5 fs-md-6 fs-6 fs-xl-3"
                            >
                              NT$ {{ item.Price.toLocaleString() }}
                            </p>
                          </div>
                          <!-- social link -->
                          <!-- <div class="d-flex justify-content-between pb-2 pt-3">
                        <a href="#" :ref="`shareLinkIcon${index}`" class="d-block" style="width: 23px;" @click.prevent="showSocialIcons(index)">
                          <img
                            src="../../assets/img/Icons-08.svg"
                            class="img-fluid"
                            width="23"
                          />
                        </a>
                        <a href="#" :ref="`closeLinkIcon${index}`" class="d-none" style="width: 23px;" @click.prevent="closeSocialIcons(index)">
                          <img src="@/assets/img/Icons-15.svg" alt="close icon">
                        </a>
                        <a
                          href="#"
                          @click.prevent="removeTrackList(item)"
                          v-if="item.isaddToTrack"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            viewBox="0 0 19.808 18.233"
                          >
                            <path
                              id="yesgo_icon元件-29"
                              d="M19.44,5.88a4.8,4.8,0,0,0-9.19-1.94A4.8,4.8,0,1,0,2.51,9.31l7.74,8.29L18,9.31A4.78,4.78,0,0,0,19.44,5.88Z"
                              transform="translate(-0.307 -0.355)"
                              fill="#f8412e"
                              stroke="#f8412e"
                              stroke-miterlimit="10"
                              stroke-width="1.35"
                            />
                          </svg>
                        </a>
                        <a href="#" @click.prevent="addtoTrackList(item)" v-else>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            viewBox="0 0 19.808 17.918"
                          >
                            <path
                              id="yesgo_icon元件-28"
                              d="M19.44,6a4.8,4.8,0,0,0-9.19-1.9A4.8,4.8,0,1,0,2.51,9.47l7.74,8.29L18,9.47A4.8,4.8,0,0,0,19.44,6Z"
                              transform="translate(-0.307 -0.517)"
                              fill="none"
                              stroke="#4d4d4d"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="1.35"
                            />
                          </svg>
                        </a>
                        <a href="#" @click.prevent="addToCart(item)">
                          <img
                            src="../../assets/img/Icons-10.svg"
                            class="img-fluid"
                            style="height:25px;"
                          />
                        </a>
                      </div> -->
                        </div>
                      </router-link>
                    </div>
                    <!-- <div :ref="`shareLinks${index}`" class="position-absolute shareBlock d-none">
                  <SocialIcons :product-id="item.productId" :name="item.name"></SocialIcons>
                </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LoadingProductList from '@/views/Loading/LoadingProductList.vue'
import ProductListSvc from '@/service/productlist-service.js'
import BannerSvc from '@/service/banner-service.js'

export default {
  data () {
    return {
      preProductsList: {
        /* 第一次接商品資料物件 */
        info: {
          products: []
        }
      },
      productsList: {
        /* 已處理完二次資料，預設最新的為第一筆 */
        info: {
          products: []
        }
      },
      productItem: [] /* 已處理三次資料，每10筆顯示一次 */,
      sortType: 'price' /* 分類標準 */,
      dataNumber: 1 /* 每頁擷取資料基礎參數 */,
      categoryId: '' /* 館別傳的參數 */,
      title: '',
      collection: '',
      isLoading: false,
      productId: '',
      productlist_banner: [],
      productlist_m_banner: []
    }
  },
  components: {
    // SocialIcons
    LoadingProductList
  },
  methods: {
    getProductList () {
      ProductListSvc.GetProductList().then(list => {
        this.isLoading = true
        this.productItem = list
        this.GA_view_item_list()
      })
    },
    getProductImage () {
      BannerSvc.GetEventBanner('ProductList_Banner').then((data) => {
        if (data) {
          this.productlist_banner = data
        }
      })
      BannerSvc.GetEventBanner('ProductList_m_Banner').then((data) => {
        if (data) {
          this.productlist_m_banner = data
        }
      })
    },
    GA_view_item_list () {
      const dataForGA = {
        items: []
      }
      dataForGA.items = JSON.parse(JSON.stringify(this.productItem))
      dataForGA.items.forEach(item => {
        item.item_id = item.Id // ?產品代碼
        item.item_name = item.Name // ?產品名稱
        item.price = item.Price // ?末端售價
        item.discount = (item.MSRP - item.Price) / item.MSRP // ?折扣率
        delete item.Date
        delete item.Image
        delete item.Slogan
        delete item.Id
        delete item.Name
        delete item.Stock
        delete item.MSRP
        delete item.Price
      })
      this.$gtag.event('view_item_list', dataForGA)
    }
    // * 加入購物車(MVVM)
    // addToCart (item) {
    //   CartSvc.AddToCart(item)
    // },
    // * 加入追蹤清單(MVVM)
    // addtoTrackList (item) {
    //   trackListSvc.AddToTrackList(item).then(data => {
    //     if (data) {
    //       item.isaddToTrack = true
    //     }
    //   })
    // },
    // * 移除追蹤清單(MVVM)
    // removeTrackList (item) {
    //   trackListSvc.RemoveToTrackList(item).then(data => {
    //     if (data) {
    //       item.isaddToTrack = false
    //     }
    //   })
    // },
    //* 開啟 Social icons
    // showSocialIcons (index) {
    //   this.$refs[`shareLinkIcon${index}`].classList.add('d-none')
    //   this.$refs[`shareLinks${index}`].classList.remove('d-none')
    //   this.$refs[`closeLinkIcon${index}`].classList.remove('d-none')
    // },
    //* 關閉 Social icons
    // closeSocialIcons (index) {
    //   this.$refs[`shareLinkIcon${index}`].classList.remove('d-none')
    //   this.$refs[`shareLinks${index}`].classList.add('d-none')
    //   this.$refs[`closeLinkIcon${index}`].classList.add('d-none')
    // }
    // getCollection () {
    //   trackListSvc.GetCollectionList().then(data => {
    //     this.collection = data
    //     console.log(this.collection)
    //   })
    // },
    // checkCookie () {
    //   const AccessToken = document.cookie.replace(/(?:(?:^|.*;\s*)LoginToken\s*=\s*([^;]*).*$)|^.*$/, '$1')
    //   const RefreshToken = document.cookie.replace(/(?:(?:^|.*;\s*)RefreshToken\s*=\s*([^;]*).*$)|^.*$/, '$1')
    //   if (AccessToken || RefreshToken) {
    //     this.getCollection()
    //   }
    // }
  },
  mounted () {
    this.getProductList()
    this.getProductImage()
  }
}
</script>

<style lang="scss" scoped>
.card {
  border-color: #c8c9cc;
}

.contain {
  width: 130px;
  height: 35px;
  color: #fff;
  line-height: 1.5;
  background: gray;
  border-radius: 30px;
  margin-top: -15%;
}

.sold_out {
  width: 100%;
  height: 100%;
  opacity: 0.5;
  border: #fff;
  background: #fff;
}

// .shareBlock{
//   width: 28px;
//   left: 22px;
//   bottom: 42px;
//   transition: .8s all cubic-bezier(0, 0.26, 0, 1.04);
// }
</style>
